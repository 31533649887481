var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.entities,"dense":""},scopedSlots:_vm._u([{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"32"}},[_c('img',{attrs:{"src":item.logo
                ? (_vm.apiUrl + "/images/entity/" + (item.logo))
                : "/logo_default.png","alt":item.nmae}})])]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('div',{key:item.id,staticClass:"text-center"},[_c('v-switch',{staticClass:"mt-1 ml-6 justify-end",attrs:{"error":!item.status,"success":item.status,"dense":"","hide-details":""},on:{"change":function($event){return _vm.updateEntityStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1)]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{key:item.id,staticClass:"text-right"},[_c('v-btn',{staticClass:"ma-2",attrs:{"height":"30px","color":"blue","x-small":"","dark":""},on:{"click":function($event){return _vm.openLoginConfirmeDialog(item.email)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-wrench-outline")])],1),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"success","x-small":"","height":"30px","dark":""},on:{"click":function($event){return _vm.$router.push(("/root/entities/finances/" + (item.id)))}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("monetization_on")])],1),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"blue","x-small":"","height":"30px","dark":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye-outline")])],1),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","x-small":"","height":"30px","dark":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"error","x-small":"","height":"30px","dark":""},on:{"click":function($event){return _vm.openConfirmeDialog(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)]}}],null,true)}),_c('confirm-dialog',{attrs:{"dialog":_vm.confirm,"id":_vm.removeId,"question":_vm.loginToEntity ? "Pretende logar na conta selecionada?" : "Pretende remover a entidade?"},on:{"yes":function($event){_vm.loginToEntity ? _vm.loginToEntityAccount() : _vm.deleteEntity()},"no":function($event){_vm.confirm = false}}}),_c('error-dialog',{attrs:{"dialog":_vm.showError,"message":_vm.error},on:{"close":function($event){_vm.showError = false}}}),_c('progress-dialog',{attrs:{"processing":_vm.isLoading}}),_c('success-dialog',{attrs:{"dialog":_vm.showSuccess,"message":_vm.success},on:{"close":function($event){_vm.showSuccess = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }